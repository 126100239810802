// extracted by mini-css-extract-plugin
var _1 = "smgl-PaymentStatus__container";
var _2 = "smgl-PaymentStatus__description";
var _3 = "smgl-PaymentStatus__failureIcon smgl-PaymentStatus__icon";
var _4 = "smgl-PaymentStatus__icon";
var _5 = "smgl-PaymentStatus__isFailure";
var _6 = "smgl-PaymentStatus__isSuccess";
var _7 = "smgl-PaymentStatus__loader";
var _8 = "smgl-PaymentStatus__loaderContainer";
var _9 = "smgl-PaymentStatus__spin";
var _a = "smgl-PaymentStatus__successIcon smgl-PaymentStatus__icon";
var _b = "smgl-PaymentStatus__title";
export { _1 as "container", _2 as "description", _3 as "failureIcon", _4 as "icon", _5 as "isFailure", _6 as "isSuccess", _7 as "loader", _8 as "loaderContainer", _9 as "spin", _a as "successIcon", _b as "title" }
